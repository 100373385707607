import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sidekickInit from '../../utils/sidekick/init';
import styles from './BannerFullSize.module.scss';
import ErrorBoundary from '../ErrorBoundary';
import ModuleText from '../ModuleText';
import Image, { ImagePropTypes } from '../Image';
import { ElementLinkPropTypes } from '../ElementLink';
import BackgroundImage from '../../generics/BackgroundImage';

export const BannerFullSizePropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  textTheme: PropTypes.oneOf(['light', 'dark']).isRequired,
  title: PropTypes.string.isRequired,
  supertitle: PropTypes.string,
  bodyString: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.shape(ImagePropTypes).isRequired,
  backgroundImageTablet: PropTypes.shape(ImagePropTypes),
  backgroundImageMobile: PropTypes.shape(ImagePropTypes),
  link: PropTypes.shape(ElementLinkPropTypes),
  links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)),
  icon: PropTypes.shape(ImagePropTypes),
  anchor: PropTypes.string,
  fullScreenHeight: PropTypes.bool
};

function BannerFullSize({
  _id,
  _contentTypeId,
  internalTitle,
  textTheme,
  title,
  supertitle,
  bodyString,
  fullScreenHeight,
  backgroundColor,
  backgroundImage,
  backgroundImageTablet,
  backgroundImageMobile,
  link,
  links,
  icon,
  anchor
}) {
  /**
   * If no content other than background image is provided,
   * assume it is a Contentful hack (ie. whitespace added to input fields)
   * to feature a full-bleed background image. In this case,
   * we hope the provided image has a transparent background.
   * <img> class => !!backgroundImage && isEmpty && styles.bgContain
   */
  const isEmpty = ![title, supertitle, bodyString, icon]
    .filter((str) => /\S/.test(str))
    .filter(Boolean).length;

  const { sidekicker } = sidekickInit({ _id, _contentTypeId, internalTitle });

  const isVideoBg =
    ['.mov', '.mp4', '.webm'].some((filetype) => backgroundImage?.filename?.includes(filetype)) &&
    backgroundImage.contentType.includes('video');

  return (
    <ErrorBoundary>
      <div
        style={{
          position: 'relative',
          backgroundColor,
          height: fullScreenHeight && '100vh'
        }}
        {...sidekicker('Banner Full Size')}
        className={cx(
          styles.module,
          styles[textTheme],
          !!backgroundImage && isEmpty && styles.bgContain,
          isVideoBg && styles.alignEnd,
          'BannerFullSize'
        )}
        data-testid="BannerFullSize"
      >
        <BackgroundImage
          image={backgroundImage}
          imageTablet={backgroundImageTablet}
          imageMobile={backgroundImageMobile}
          id={anchor?.toLowerCase()}
          dataTestId="BannerFullSize-backgroundImage"
        />
        <div className={cx('container', styles.content)}>
          <div className="row">
            <div className={cx('col-12 col-md-6', icon && 'd-flex flex-column flex-sm-row')}>
              {icon && (
                <div className="mr-5">
                  <Image
                    testId="BannerFullSize-icon"
                    className={styles.icon}
                    image={icon}
                    {...sidekicker('Icon')}
                  />
                </div>
              )}
              <ModuleText
                _id={_id}
                _contentTypeId={_contentTypeId}
                anchor={anchor}
                internalTitle={internalTitle}
                supertitle={supertitle}
                title={title}
                body={bodyString}
                image={backgroundImage}
                link={link}
                links={links}
                textJustification="left"
                bodySize="small"
              />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

BannerFullSize.propTypes = BannerFullSizePropTypes;
BannerFullSize.defaultProps = {
  backgroundColor: null,
  backgroundImageTablet: null,
  backgroundImageMobile: null,
  supertitle: null,
  link: null,
  links: null,
  icon: null,
  anchor: '',
  fullScreenHeight: false
};

export default BannerFullSize;
