/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { ElementLinkPropTypes } from '../ElementLink';
import { ImagePropTypes } from '../Image';

export const CategoryPropTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string,
  icon: PropTypes.shape(ImagePropTypes)
};

export const ModuleTextPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  internalTitle: PropTypes.string.isRequired,
  textJustification: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
  title: PropTypes.string.isRequired,
  supertitle: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bodySize: PropTypes.oneOf(['small', 'medium', 'large']),
  link: PropTypes.shape(ElementLinkPropTypes),
  links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)),
  icon: PropTypes.shape(ImagePropTypes),
  anchor: PropTypes.string,
  titleTag: PropTypes.string,
  backgroundColor: PropTypes.string,
  padding: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.shape(CategoryPropTypes)),
  className: PropTypes.string,
  listItemVariant: PropTypes.oneOf(['dot', 'check']),
  mobileImage: PropTypes.shape(ImagePropTypes),
  mobileDisclaimer: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
